
import {Vue, Component, Prop, Mixins, Watch} from 'vue-property-decorator';
import AlacakBelgeTuruPicker from "@/components/pickers/AlacakBelgeTuruPicker.vue";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import IlamTipiPicker from "@/components/pickers/IlamTipiPicker.vue";
import {IlamTipi} from "@/enum/IlamTipi";
import MahkemeSecim from "@/components/inputs/MahkemeSecim.vue";
import IlamBilgileriMahkemePicker from "@/components/pickers/IlamBilgileriMahkemePicker.vue";
import NoterPicker from "@/components/pickers/NoterPicker.vue";
@Component({
  components: {
    NoterPicker,
    IlamBilgileriMahkemePicker,
    MahkemeSecim,
    IlamTipiPicker,
    AlacakBelgeTuruPicker

  }
})
export default class TercihlerIlamBilgileri extends Mixins(ObjectInputMixin) {
  @Prop() takipAltTipi!:any
  @Prop() items!: Array<any>;
  ilamTipi = IlamTipi;
  ilamBilgileri:any = []
  ilamBilgisiVarMi:boolean = false

  headers = [
    {text: 'Belge Türü', value: 'alacak_belge_turu_adi'},
    {text: 'İlam Tipi', value: 'ilam_tipi'},
    {text: 'Mahkeme', value: 'mahkeme_adi'},
    {text: 'Noter', value: 'noter_adi'},
    {text: 'İlam Veren Kurum', value: 'ilam_veren_kurum'},
    {text: 'İşlemler', value: 'actions'},
  ]

  mounted(){
   this.load()
  }

  load(){
    this.$http.get(`/api/v1/get-takip-ilam-bilgileri`).then((response:any) => {
      this.ilamBilgileri = response.data;
    })
  }

  deleteIlamBilgileri(item:any){
    this.$http.delete("/api/v1/takip-alt-tip-ilam/" + item).then(() => {
      this.load()
    });
  }

  @Watch('localValue.alacak_belge_turu_id')
  changeBelgeTuru(){
    if (this.ilamBilgileri.some((ilam:any) => ilam.alacak_belge_tur_id == this.localValue.alacak_belge_turu_id.id)){
      this.ilamBilgisiVarMi = true
    }else{
      this.ilamBilgisiVarMi = false
    }
    this.input()

  }

  save(){
    if (!this.ilamBilgisiVarMi){
      this.takipAltTipi.ilam_bilgileri = this.localValue
      let ilam_bilgileri:any = {}
      if (this.localValue.ilam_tipi==this.ilamTipi.mahkeme){
        ilam_bilgileri = {
          takip_alt_tip_id: this.takipAltTipi.id,
          alacak_belge_tur_id: this.localValue.alacak_belge_turu_id.id,
          ilam_tipi: this.localValue.ilam_tipi,
          mahkeme_id: this.localValue.mahkeme_id??null,
        }
      }else if(this.localValue.ilam_tipi==this.ilamTipi.noter){
        ilam_bilgileri = {
          takip_alt_tip_id: this.takipAltTipi.id,
          alacak_belge_tur_id: this.localValue.alacak_belge_turu_id.id,
          ilam_tipi: this.localValue.ilam_tipi,
          noter_id: this.localValue.noter_id??null,
        }
      }else if(this.localValue.ilam_tipi!=this.ilamTipi.noter && this.localValue.ilam_tipi!=this.ilamTipi.mahkeme){
        ilam_bilgileri = {
          takip_alt_tip_id: this.takipAltTipi.id,
          alacak_belge_tur_id: this.localValue.alacak_belge_turu_id.id,
          ilam_tipi: this.localValue.ilam_tipi,
          ilam_veren_kurum: this.localValue.ilami_veren_kurum??null,
        }
      }
      this.$http.post(`/api/v1/takip-alt-tip-ilam`,ilam_bilgileri).then(() => {
       this.load()
      })
      this.$emit('load');
    }else{
      this.$toast.error('Kayıtlı İlam Bilgisi Vardır Lütfen İlam Bilgileri Tablosundan Kontrol Ediniz!');
    }

  }


}
